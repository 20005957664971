.menu{
  li {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

.access-button{
  display: flex !important;
}