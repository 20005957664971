h1,h2, h3{
  @apply leading-tight;
}

h1{
  //@apply text-7xl md:text-9xl text-primary font-light font-heading;
}

h2{
  //@apply text-4xl  md:text-7xl;
}

img{
  max-width: 100%;
  width: auto;
}
