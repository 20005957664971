#iPhone, #galaxy{

  .viewer{
    max-width: 290px;
    max-height: 636px;
    //overflow: hidden;
    //pointer-events: none;
  }

  .game-app{
    max-width: 290px;
    max-height: 100%;
    width: 100%;
  }

  iframe{
    display: block;
    width: 390px;
    height: 844px;
    -ms-zoom: 0.75;
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
  }
}

#galaxy {
  .viewer{
    max-width: 320px;
    max-height: 675px;
    overflow: hidden;
  }

  .game-app{
    max-width: 320px;
    max-height: 100%;
  }

  iframe{
    width: 425px;
    height: 900px;
  }
}

#ipad {
  .viewer{

    max-width: 520px;
    max-height: 694px;
    overflow: hidden;
  }

  .game-app{
    width: 100%;
    max-width: 520px;
    max-height: 100%;

    img{
      width: 100%;
      max-width: unset;
    }
  }

  iframe{
    width: 520px;
    height: 900px;
  }
}